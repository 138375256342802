<template>
  <div class="content-list-content">
    <div class="content-list-table">
      <div class="mb-2 d-flex justify-content-between mb-5">
        <div class="w-100">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
        </div>
      </div>
      <div v-if="listMenu && listMenu.length > 0">
        <div
          class="list-option mb-2 d-flex justify-content-center"
          v-for="(value, index) in listMenu"
          :key="index"
        >
          <div class="once-option">
            <router-link
              v-if="value.route"
              :to="{ name: value.route, params: { daily: value.daily } }"
              class=""
            >
              <button class="float-right button-option-setting">
                <span v-html="value.text"></span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "globalSetting",
  components: {},
  data() {
    return {
      listMenu: [
        { text: "売上（月別）", route: "saleManagementMonthly", daily: "" },
        { text: "売上（日別）", route: "saleManagementdaily", daily: "" },
        {
          text: "売上（日別詳細）",
          route: "detailSaleManagementDaily",
          daily: "",
        },
        {
          text: "売上（ユーザー毎）",
          route: "listSaleManagementRanks",
          daily: "",
        },
      ],
      Date_Now: null,
    };
  },
  created() {
    const getDate = new Date();
    const DatefomatDaily = [
      getDate.getFullYear(),
      ("00" + (getDate.getMonth() + 1)).substr(-2),
    ].join("-");
    this.listMenu[1].daily = DatefomatDaily;
    console.log(this.listMenu[1]);
    const detailDatefomatDaily = [
      getDate.getFullYear(),
      ("00" + (getDate.getMonth() + 1)).substr(-2),
      getDate.getDate(),
    ].join("-");
    this.listMenu[2].daily = detailDatefomatDaily;
    //Fix domain
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listMenu.forEach((obj) => {
        obj.route += " domain";
      });
    }
  },
  computed: {},
  watch: {},
  methods: {
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminEdit" });
      } else {
        this.$router.push({ name: "DashboardAdminEdit domain" });
      }
    },
  },
};
</script>
